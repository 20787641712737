<template>
  <Swiper
    v-if="!withThumbnails"
    slides-per-view="1"
    :modules="modules"
    space-between="0.5rem"
    :free-mode="false"
    class="collection-carousel"
    wrapper-tag="ul"
    :navigation="true"
    :pagination="{ clickable: true }"
    :a11y="{ slideRole: '' }"
    @slide-change="handleSlideChange"
  >
    <SwiperSlide
      v-for="item in items"
      :key="item._uid"
      tag="li"
      @click="tracking(item)"
    >
      <StoryblokComponent
        :blok="item"
        :stop-video="initState.value"
        :class="childClasses"
        :context="context"
        :level="childLevel"
        :is-centered="childCentered"
        :text-size="childTextSize"
        :title-size="childTitleSize"
        :size-hint="sizeHint"
        :is-guide="isGuide"
        :is-editorial="isEditorial"
        :is-file="isFile"
        :style="item.background_color ? {'--parent-bg': `${item.background_color} !important`, 'background': `${item.background_color} !important` }: {}"
      />
    </SwiperSlide>
  </Swiper>

  <div v-else class="carousel-with-thumbnails">
    <swiper
      :slides-per-view="1"
      :initial-slide="clickedSlide"
      class="collection-carousel"
      space-between="20%"
      wrapper-tag="ul"
      navigation
      :modules="modules"
      :thumbs="{ swiper: thumbsSwiper }"
      :pagination="{ clickable: true }"
      @slide-change="handleSlideChange"
    >
      <swiper-slide v-for="item in items" :key="item._uid" tag="li"> 
        <StoryblokComponent
          :blok="item"
          :stop-video="initState.value"
          :size-hint="sizeHint"
        />
      </swiper-slide>
    </swiper>
    <swiper
      :modules="[Thumbs]"
      watch-slides-progress
      :slides-per-view="7"
      space-between="8"
      wrapper-tag="ul"
      :free-mode="true"
      :class="`collection-carousel-thumbs${
        items?.length < 7 ? ' centered' : ''
      }`"
      @swiper="setThumbsSwiper"
    >
      <swiper-slide v-for="item in items" :key="item._uid" tag="li">
        <StoryblokComponent :blok="item" :play-disabled="true" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { Navigation, Pagination, A11y, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import "swiper/css/thumbs";

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  withThumbnails: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  clickedSlide: {
    type: Number,
    required: false,
    default: () => 0,
  },
  childClasses: {
    type: String,
    required: false,
    default: "",
  },
  context: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  childLevel: {
    type: Number,
    required: false,
    default: () => 1,
  },
  childCentered: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  childTextSize: {
    type: String,
    required: false,
    default: "size-xs",
  },
  childTitleSize: {
    type: String,
    required: false,
    default: "size-m",
  },
  sizeHint: {
    type: Number,
    required: false,
    default: 100,
  },
  isFile: {
    type: Boolean,
    required: false,
    default: false,
  },
  isEditorial: {
    type: Boolean,
    required: false,
    default: false,
  },
  isGuide: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const trackingStore = useTrackingStore();
const modules = computed(() => {
  return [Navigation, Pagination, A11y, Thumbs];
});

const initState = reactive({ value: false });

onMounted(() => {
  const nextButton = document.querySelector(".swiper-button-next");
  const prevButton = document.querySelector(".swiper-button-prev");
  
  if (nextButton) {
    nextButton.setAttribute("aria-label", useMicroCopy("slide.button.next"));
  }

  if (prevButton) {
    prevButton.setAttribute("aria-label", useMicroCopy("slide.button.previous"));
  }
});

const handleSlideChange = () => {
  initState.value = true;
  setTimeout(() => {
    initState.value = false;
  });
};

const tracking = (data) => {
  if ("isPrivate" in data) {
    trackingStore.sendTrackingData({
      event: "click.action",
      click: useSnakeCaseFilter(data.cta_label),
      click_chapter1: "facilities_recommendation",
      click_chapter2: "CTA",
      click_chapter3: useSnakeCaseFilter(getTextFromRichText(data.title)),
    });
    return;
  } else {
    trackingStore.sendTrackingData({
      event: "click.action",
      click: useSnakeCaseFilter(data.cta_label),
      click_chapter1: "push_cards",
      click_chapter2: "CTA",
      click_chapter3: useSnakeCaseFilter(getTextFromRichText(data.title)),
    });
    return;
  }
  if (props.childClasses === "news-card") {
    trackingStore.sendTrackingData({
      event: "click.navigation",
      click: "learn_more",
      click_chapter1: "news",
      click_chapter2: "carrousel",
      click_chapter3: useSnakeCaseFilter(getTextFromRichText(data?.title)),
    });
  }
  if (props.childClasses === "card") {
    trackingStore.sendTrackingData({
      event: "click.navigation",
      click: useSnakeCaseFilter(data?.cta_label),
      click_chapter1: "push_cards",
      click_chapter2: "CTA",
      click_chapter3: useSnakeCaseFilter(getTextFromRichText(data?.title)),
    });
  }
};

const thumbsSwiper = ref(null);

const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};
</script>

<style lang="scss">
@mixin swiper-button {
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;

  &:after {
    font-size: 0;
    width: 3.25rem;
    height: 3.25rem;
    padding: 0;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.collection-carousel {
  border-radius: $radius-l;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .swiper-wrapper {
    margin: 0;
    list-style: none;
    padding: 0;
    border-radius: $radius-l;
  }

  img {
    object-fit: cover;
    display: block;
    width: 100%;
    aspect-ratio: 1.33 !important;
  }

  .media-text-cta .media {
    border-radius: $radius-s;
  }

  .youtube-player {
    aspect-ratio: 1.33 !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include swiper-button;
    background-color: var(--solid-07);
  }

  .swiper-button-prev:after {
    background-image: url("../assets/svg/func_left.svg");
  }

  .swiper-button-next:after {
    background-image: url("../assets/svg/func_right.svg");
  }

  .swiper-pagination {
    width: fit-content;
    position: relative;
    margin: -1.375rem auto 0 auto;
    left: auto;
  }

  .swiper-pagination-bullet {
    background-color: var(--solid-07);
    opacity: 0.75;
  }

  .swiper-pagination-bullet-active {
    width: 2rem;
    border-radius: 0.25rem;
    opacity: 1;
  }
}

.carousel-with-thumbnails {
  grid-column: 3 / -3;
  grid-row: 2 / 2;

  &:before {
    background: var(--solid-07);
    position: absolute;
    @include inset(0, 10vw);
    content: "";
    box-shadow: var(--solid-07) 0 0 0 0.5rem;
    border-radius: $radius-l;
  }

  .collection-carousel {
    padding: 0 10vw;

    .swiper-button-prev,
    .swiper-button-next {
      @include swiper-button;
      @include gradientAlt;
      background-color: transparent;

      &::before {
        content: "";
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background-color: var(--solid-03);
      }

      &:after {
        z-index: 12;
        background-color: transparent;
      }
    }
  }

  .swiper-pagination {
    height: auto;
    display: flex;
    align-items: center;
    top: auto !important;
    bottom: auto !important;
    margin-top: -0.5rem;
    transform: translateY(-1rem);
    justify-content: center;
  }

  .swiper-pagination-bullet-active {
    width: 3rem;
  }

  .collection-carousel-thumbs {
    width: 59.5vw;
    margin-top: 0.5rem;
    border-radius: $radius-l;
    overflow: hidden;

    &.centered .swiper-wrapper {
      display: flex;
      justify-content: center;
    }

    .swiper-slide {
      opacity: 0.6;
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }

    img {
      border-radius: $radius-l;
      object-fit: cover;
      display: block;
      cursor: pointer;
      aspect-ratio: 1.33;
    }

    .youtube-player {
      aspect-ratio: 1.33 !important;
    }
  }
}

.carousel-navigation-bottom {
  padding-bottom: 5.75rem !important;
  border-radius: 0;

  &.lock {
    padding-bottom: 0 !important;
  }

  > .swiper-button-prev,
  > .swiper-button-next {
    @include swiper-button;
    @include gradientAlt;

    bottom: 0;
    top: auto;
    left: auto;
    z-index: 11;

    &::before {
      content: "";
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      background-color: var(--solid-07);
    }

    &:after {
      z-index: 12;
    }
  }

  > .swiper-button-prev {
    right: calc(3.25rem + 0.75rem + 1.12rem);

    @include for-tablet-portrait-up {
      right: calc(3.25rem + 0.75rem + 2.5rem);
    }

    @include for-desktop-up {
      right: calc(3.25rem + 0.75rem + 3.75rem);
    }

    &:after {
      background-image: url("../assets/svg/func_left.svg");
    }
  }

  > .swiper-button-next {
    right: 1.12rem;

    @include for-tablet-portrait-up {
      right: 2.5rem;
    }

    @include for-desktop-up {
      right: 3.75rem;
    }

    &:after {
      background-image: url("../assets/svg/func_right.svg");
    }
  }

  > .swiper-pagination {
    height: 3.25rem;
    display: flex;
    align-items: center;
    top: auto !important;
    bottom: 0 !important;
    left: 1.12rem;
    width: 100%;
    position: absolute;

    @include for-tablet-portrait-up {
      left: 0;
      padding-right: col-span();
    }

    @include for-desktop-up {
      justify-content: center;
    }

    .swiper-pagination-bullet {
      background: var(--text-50);
      opacity: 1;
      width: 0.5rem;
      height: 0.5rem;
    }

    .swiper-pagination-bullet-active {
      @include gradientAlt;
      width: 3rem;
      height: 0.5rem;
      border-radius: 0.25rem;
    }
  }
}
// Design for carousel-full-width
.carousel-full-width {
  @include for-tablet-portrait-up {
    margin-left: 0 !important;
    margin-right: 0 !important;
    overflow: visible !important;

    .swiper {
      overflow: visible !important;
    }
    .swiper-button-prev {
      right: 4rem;
    }
    .swiper-button-next {
      right: 0;
    }
  }
}
</style>
